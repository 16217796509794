import connection from 'connection/instance';
import { AxiosResponse } from 'axios';
import type {
  ARTICLE_FILTER_TYPE,
  ArticleRequest,
  IArticleResponse,
  IArticlesMeta,
  IArticlesFetchParams,
  IArticleResponseData,
  DiseaseRequest
} from 'types';

export interface IArticlesParams {
  id: string;
  article: ArticleRequest | DiseaseRequest;
}

// Общая функция для обработки ответов API
const handleApiResponse = <T>(response: AxiosResponse<IArticleResponseData<T, IArticlesMeta>>): T => {
  return response.data.data;
};

export const articlesFetch = async (type: ARTICLE_FILTER_TYPE): Promise<IArticleResponse[]> => {
  const params: IArticlesFetchParams = {
    data: {
      filter: { type },
      paginate: {
        limit: 500,
        page: 1
      }
    }
  };

  const response = await connection.get<IArticleResponseData<IArticleResponse[], IArticlesMeta>>('/api/articles', {
    params
  });
  return handleApiResponse(response);
};

export const articlesFetchOne = async (id: string): Promise<IArticleResponse> => {
  const response = await connection.get<IArticleResponseData<IArticleResponse, IArticlesMeta>>(`/api/articles/${id}`);
  return handleApiResponse(response);
};

export const articlesCreate = async (article: ArticleRequest | DiseaseRequest): Promise<IArticleResponse> => {
  const response = await connection.post<IArticleResponseData<IArticleResponse, IArticlesMeta>>('/api/articles', {
    data: article
  });
  return handleApiResponse(response);
};

export const articlesUpdate = async (data: IArticlesParams): Promise<IArticleResponse> => {
  const response = await connection.put<IArticleResponseData<IArticleResponse, IArticlesMeta>>(
    `/api/articles/${data.id}`,
    { data: data.article }
  );
  return handleApiResponse(response);
};

export const articlesDelete = async (id: string): Promise<IArticleResponse> => {
  const response = await connection.delete<IArticleResponseData<IArticleResponse, IArticlesMeta>>(
    `/api/articles/${id}`
  );
  return handleApiResponse(response);
};

export const articlesPublish = async (id: string): Promise<IArticleResponse> => {
  const response = await connection.put<IArticleResponseData<IArticleResponse, IArticlesMeta>>(
    `/api/articles/${id}/publish`
  );
  return handleApiResponse(response);
};

export const articlesUnpublish = async (id: string): Promise<IArticleResponse> => {
  const response = await connection.put<IArticleResponseData<IArticleResponse, IArticlesMeta>>(
    `/api/articles/${id}/unpublish`
  );
  return handleApiResponse(response);
};
