import * as yup from 'yup';
import _omitBy from 'lodash/omitBy';
import _isNil from 'lodash/isNil';
import _extend from 'lodash/extend';

export const enum OSTROVOK_FIELDS {
  PROVIDER_ID = 'provider_id',
  PROVIDER = 'provider',
  LOCATION = 'location',
  PLACE_ID = 'place_id',
  COORDINATES = 'coordinates',
  LAT = 'lat',
  LNG = 'lng',
  TIMEZONE = 'timezone',
  DISCOUNT = 'discount',
  INSTANT_BOOKING = 'instant_booking',
  CASHBACK = 'cashback',
  EXTERNAL_PROVIDER = 'external_provider',
  TRIPADVISOR_URL = 'tripadvisor_url',
  VIDEOS = 'videos',
  SOURCES = 'sources',
  INSURANCE_ID = 'insurance_id',
  NUMBER = 'number',
  COMMISSION = 'commission_in_percent',
  DATE = 'from_date',
  CONTRACTS = 'contracts',
}

export const validationSchema = () =>
  yup.object({
    [OSTROVOK_FIELDS.PROVIDER_ID]: yup.string().required('Обязательное поле'),
    [OSTROVOK_FIELDS.LOCATION]: yup.string().required('Обязательное поле'),
    [OSTROVOK_FIELDS.PLACE_ID]: yup.string().required('Обязательное поле'),
    [OSTROVOK_FIELDS.COORDINATES]: yup.object().shape({
      [OSTROVOK_FIELDS.LAT]: yup
        .string()
        .matches(/^-?([1-8]?[0-9](\.\d+)?|90(\.0+)?)$/, 'Некорректный формат широты. Пример: 45.9999999')
        .required('Обязательное поле'),
      [OSTROVOK_FIELDS.LNG]: yup
        .string()
        .matches(
          /^-?((1[0-7][0-9]|[1-9]?[0-9])(\.\d+)?|180(\.0+)?)$/,
          'Некорректный формат долготы. Пример: 45.9999999'
        )
        .required('Обязательное поле')
    }),
    [OSTROVOK_FIELDS.TIMEZONE]: yup.string().required('Обязательное поле'),
    [OSTROVOK_FIELDS.DISCOUNT]: yup
      .number()
      .transform((value) => (isNaN(value) ? 0 : value))
      .min(0, 'Значение должно быть не менее 0')
      .max(100, 'Значение должно быть не более 100'),
    [OSTROVOK_FIELDS.EXTERNAL_PROVIDER]: yup.object().shape({
      [OSTROVOK_FIELDS.TRIPADVISOR_URL]: yup
        .string()
        .nullable()
        .transform((value) => (value && value.trim() ? value : ''))
        .url('Введите корректную ссылку')
    })
    // [OSTROVOK_FIELDS.VIDEOS]: yup.string()
  });

export const defaultValues = {
  [OSTROVOK_FIELDS.PROVIDER_ID]: '',
  [OSTROVOK_FIELDS.LOCATION]: '',
  [OSTROVOK_FIELDS.PLACE_ID]: '',
  [OSTROVOK_FIELDS.COORDINATES]: {
    [OSTROVOK_FIELDS.LAT]: '',
    [OSTROVOK_FIELDS.LNG]: ''
  },
  [OSTROVOK_FIELDS.TIMEZONE]: 'Europe/Moscow',
  [OSTROVOK_FIELDS.DISCOUNT]: '',
  [OSTROVOK_FIELDS.INSTANT_BOOKING]: false,
  [OSTROVOK_FIELDS.CASHBACK]: false,
  [OSTROVOK_FIELDS.EXTERNAL_PROVIDER]: {
    [OSTROVOK_FIELDS.TRIPADVISOR_URL]: ''
  },
  [OSTROVOK_FIELDS.VIDEOS]: ''
};

export const extendDefaultValues = (values: object | null = {}) => {
  return _extend({}, defaultValues, _omitBy(values, _isNil));
};
